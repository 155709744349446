






































































































































import { defineComponent, reactive, watch } from '@vue/composition-api'

export default defineComponent({
	name: 'DefaultLayout',

	props: {},

	setup(props, { root }) {
		const state = reactive({
			loggedIn: false,
			loginInfo: {
				firstName: ''
			}
		})

		const logOut = () => {
			root.$store.dispatch('resetLoginInfo')
			root.$router.push('/login')
		}

		watch (
			() => root.$store.state.loginInfo,
			() => {
				state.loginInfo = root.$store.getters.loginInfo
				state.loggedIn = state.loginInfo.firstName != ''
			},
			{ deep: true }
		)

		state.loginInfo = root.$store.getters.loginInfo
		state.loggedIn = state.loginInfo.firstName != ''

		return { state, logOut }
	}
})
